export interface IBuySubscriptionProps {
  linkPath: string;
  querySymbol?: string;
  query: string;
}

export interface IVideoPlansOption {
  text: string;
  value: string;
  price: number;
}

export type SubscriptionType = 'core' | 'video' | 'slideshow' | 'brand' | 'domain';

export interface ISubscriptionItem {
  amountCents: number;
  amountCentsGross: number;
  amountCentsDiscountedGross?: number;
  nextPaymentAt: string | null;
  canceledDate: string | null;
  orderId: string | null;
  paymentMethod: string | null;
  renewUrl: string | null;
  stripeSubscriptionId: string | null;
  subscriptionType: SubscriptionType;
  isCanceled?: boolean;
  canCancelBefore?: Date;
  currency: string;
  successfulPayments?: number;
}

export interface ICancelPayload {
  subscriptionType: SubscriptionType;
  questionnaireAnswers: Array<{
    questionId: string;
    answerId?: string;
    answer?: string | boolean;
  }>;
}

export enum IRestartSubscriptionActionSource {
  ActiveCancelledSubModal = 'ActiveCancelledSubModal',
  OverviewSub = 'OverviewSub'
}

export interface IRestartSubscription {
  subscriptionType: SubscriptionType;
  actionSource: IRestartSubscriptionActionSource;
}

export type IDeal = {
  id: string;
  discountPercent?: number;
  discountFixedAmount?: number;
  discountType: 'percent' | 'fixed';
  freeTemplates?: Array<string>;
  freeSlideshow: boolean;
  enableBillingCycleSelection: boolean;
  enablePlanSelection: boolean;
};

export type TVidePlansOptions = IVideoPlansOption[];

export interface IUpdateBillingAddress {
  email?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  street?: string;
  streetNumber?: string;
  zipcode?: string;
  city?: string;
}

export interface IUpdateBillingAddressPayload extends IUpdateBillingAddress {
  routerPath: string;
  justUpdate?: boolean;
  waitForTaxSettingsUpdate?: boolean;
}

export const PaymentMethods = {
  usd: ['card', 'paypal'],
  eur: ['card', 'paypal', 'sepa_debit']
};
