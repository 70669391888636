import Api from '../../utils/API';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { putPaymentInfoAction } from '../../actions';
import { getPaymentInfo } from '../../selectors/paymentInfo';
import {
  ICalculateDiscountParams,
  IGetProrationsParams,
  IUpdateAddonsParams,
  IUpdateSubscriptionParams,
  IUpdateSubscriptionSagaParams
} from '../../types/subscription';
import { ISagaAction } from '../../types';
import ApiErrors from '../../utils/API/APIErrors';

export const paymentInfoSaga = function* (): SagaIterator {
  try {
    const paymentInfo = yield call(Api.AccessRules.getPaymentInfo);

    if (paymentInfo?.result) {
      yield put(putPaymentInfoAction(paymentInfo.result));
    }
  } catch (e) {
    console.error('Failed to track event', e);
  }
};

export const fetchPricing = function* (): SagaIterator {
  try {
    const paymentInfo = yield select(getPaymentInfo);
    yield put(
      putPaymentInfoAction({
        pricing: {
          loading: true,
          ...paymentInfo.pricing
        }
      })
    );
    const pricing = yield call(Api.Subscription.getPricing);

    if (pricing?.result) {
      const paymentInfo = yield select(getPaymentInfo);
      yield put(putPaymentInfoAction({ ...paymentInfo, pricing: pricing.result }));
    }
  } catch (e) {
    console.error('Failed to track event', e);
  }
};

export const calculateDiscountSaga = function* ({
  payload
}: ISagaAction<ICalculateDiscountParams>): SagaIterator {
  try {
    const discount = yield call(Api.Subscription.calculateDiscount, payload);

    if (discount?.result) {
      const paymentInfo = yield select(getPaymentInfo);
      yield put(putPaymentInfoAction({ ...paymentInfo, discount: discount.result }));
    }
  } catch (e) {
    console.error('Failed to track event', e);
  }
};

export const getProrationsSaga = function* ({
  payload
}: ISagaAction<IGetProrationsParams>): SagaIterator {
  try {
    const paymentInfo = yield select(getPaymentInfo);
    yield put(
      putPaymentInfoAction({
        ...paymentInfo,
        prorations: {
          ...paymentInfo.prorations,
          loading: true
        }
      })
    );
    const prorations = yield call(Api.Subscription.getProrations, payload);

    if (prorations?.result) {
      const paymentInfo = yield select(getPaymentInfo);
      yield put(putPaymentInfoAction({ ...paymentInfo, prorations: prorations.result }));
    }
  } catch (e) {
    console.error('Failed to track event', e);
  }
};

export const getDigistoreBalanceSaga = function* ({
  payload: prorationDateSec
}: ISagaAction<number>): SagaIterator {
  try {
    const response = yield call(Api.Subscription.getDigistoreBalance, prorationDateSec);

    const paymentInfo = yield select(getPaymentInfo);
    yield put(
      putPaymentInfoAction({ ...paymentInfo, digistoreBalance: response.result.digistoreBalance })
    );
  } catch (e) {
    console.error('Failed to fetch digistore balance', e);
  }
};

export const updateSubscriptionSaga = function* ({
  payload
}: ISagaAction<IUpdateSubscriptionParams>): SagaIterator {
  try {
    const response = yield call(Api.Subscription.updateSubscription, payload);
    ApiErrors.checkOnApiError(response);
  } catch (e) {
    console.error('Failed to track event', e);
  }
};

export const updateAddonsSaga = function* ({
  payload
}: ISagaAction<IUpdateAddonsParams>): SagaIterator {
  try {
    const response = yield call(Api.Subscription.updateAddons, payload);
    ApiErrors.checkOnApiError(response);
  } catch (e) {
    console.error('Failed to track event', e);
  }
};

export const fetchStripePaymentMethodStateSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Subscription.userHasPaymentMethod);
    ApiErrors.checkOnApiError(response);

    const paymentInfo = yield select(getPaymentInfo);
    yield put(
      putPaymentInfoAction({
        ...paymentInfo,
        userHasPaymentMethod: response.result.hasPaymentMethod
      })
    );
  } catch (e) {
    console.error('Failed to track event', e);
  }
};
