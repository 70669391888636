import {
  addLogoSlide,
  cancelOrderAction,
  changeSlideshowPublishStatus,
  createEmailPackage,
  deleteEmailPackage,
  editLogoSlide,
  fetchEmailPackages,
  fetchPayDocumentsAction,
  getAvailableCurrenciesAction,
  fetchProductsAction,
  salesAutomationBulkUpdateAction,
  searchCollectionsAction,
  setFocalPointAction,
  deleteEmailTemplateAction,
  userHasEnoughInvoicingDataAction,
  selectImages,
  sendLoginLinkEmailAction,
  updateMainCustomers,
  deleteMainCustomer,
  setEndCustomerEmailSubscription,
  fetchPricingAction,
  calculateDiscountAction,
  getProrationsAction,
  updateSubscriptionAction,
  updateAddonsAction,
  setupIntentFailed,
  getDigistoreBalanceAction,
  setSelectedPriceListAction,
  fetchStripePaymentMethodState,
  recordPaymentAction,
  actionDropImage,
  changeCollectionState,
  changeGalleryOrder,
  createGallery,
  deleteCollectionCustomDomain,
  deleteGallery,
  deleteGalleryImages,
  deleteImage,
  deleteImages,
  fetchCollection,
  fetchCollectionStatistics,
  fetchCollectionStatisticsGraph,
  fetchCollectionStatisticsOverall,
  fetchDomains,
  fetchGalleries,
  fetchGallery,
  fetchImages,
  fetchSelections,
  fetchTemplates,
  galleryUploadingAction,
  galleryUploadingClearAction,
  getPin,
  moveSelectedImagesToGallery,
  multipleGalleryUpdate,
  multipleWatermarkGalleryUpdate,
  selectImage,
  selectionCleanUp,
  setAppIcon,
  setImageTitle,
  showNotifyFail,
  showNotifySuccess,
  toggleGalleryWatermark,
  toggleImageWatermark,
  updateCollectionByPath,
  updateCollectionDomain,
  updateGallery,
  updateSingleImageData,
  uploadImages,
  updateGraphFilter,
  statisticsInitialFetch,
  startUploadGalleryVideo,
  deleteGalleryVideo,
  invertSelectedImages,
  refreshActivities,
  selectSelectionImagesByFilter,
  buySubscription,
  cancelSubscription,
  actionDropMultiImage,
  logUserActivity,
  startWaterMarkProcessing,
  galleriesUploadingClearAction,
  createPresetAction,
  fetchPresetsAction,
  setPresetAsDefault,
  deletePreset,
  updatePresetAction,
  setPresetsHeaderImageSagaAction,
  fetchProductPricingListAction,
  createProductPriceListAction,
  fetchUserPricingAction,
  updatePriceValueAction,
  fetchUserAccountBalanceAction,
  fetchCouponsAction,
  createCoupon,
  updateUserTaxAction,
  createEmailTemplateAction,
  fetchRecipientsAction,
  getEmailTemplatePreviewAction,
  fetchEmailTemplatesAction,
  updateEmailTemplateAction,
  sendEmailAction,
  deleteCoupon,
  updateCoupon,
  fetchProductGroupsAction,
  getGlobalShopSettingsForCurrentUser,
  createGlobalShopSettings,
  updateGlobalShopSettings,
  fetchPayoutAccountAction,
  createPayoutAccountAction,
  updatePayoutAccountAction,
  fetchLaboratoriesAction,
  fetchOrderListForUserAction,
  fetchOrderImagesAction,
  deleteProductPriceListAction,
  fetchTemplatesFonts,
  setSmallScreenImageTitle,
  createSlideshow,
  fetchSlideshow,
  updateSlideshow,
  addAdditionalPhotos,
  addTitleSlide,
  reorderSlideshow,
  editSlideTitle,
  fetchMusic,
  removeSoundtrack,
  filterMusic,
  fetchFavoriteMusic,
  addMusicToFavorites,
  removeSlideshow,
  undoAction,
  redoAction,
  removeFavorites,
  removeSlides,
  fetchSlideshowsList,
  changeSlideshowParameters,
  reorderTracks,
  scheduleRenderAction,
  cancelRenderAction,
  getRenderServiceStatusAction,
  loadMore,
  getRenderStatusAction,
  fetchSlideshowMusic,
  updateUserSlideshowOnboardingAction,
  accessRulesAction,
  startTrialAction,
  fetchPaymentInfoAction,
  restartSubscription,
  updateBillingAddress,
  setGifMetaData,
  setCollectionHeader,
  deleteHeaderImage,
  fetchGalleriesTags,
  addGalleryTagCollectionAction,
  removeGalleryTagCollectionAction,
  fetchProductUserPricingGroupsListAction,
  updatePricingGroupAction,
  createProductPriceListAction2,
  renamePricingListAction,
  createDigitalProductAction,
  updateProductUserPricingsAction
} from '../actions';
import { SagaIterator } from 'redux-saga';
import { takeLatest, takeEvery, fork, debounce, takeLeading } from 'redux-saga/effects';

import * as collections from './collections';
import * as domains from './domains';
import * as templates from './templates';
import * as galleries from './galleries';
import * as images from './images';
import * as global from './global';
import * as selection from './selections';
import * as statistics from './statistics';
import * as subscription from './subscription';
import * as watermarks from './watermarks';
import * as presets from './presets';
import * as shop from './shop';
import * as digitalPricing from './digital_pricing';
import * as user from './user';
import * as countries from './countries';
import * as emailTemplates from './email_templates';
import * as slideshow from './slideshow';
import * as music from './music';
import * as slideshowsList from './slideshowsList';
import * as analytics from './analytics';
import * as accessRules from './accessRules';
import * as trial from './trial';
import * as paymentInfo from './paymentInfo';
import * as galleriesTags from './galleriesTags';
import * as emailSettings from './email_settings';
import * as message from './message';
import * as portfolios from './portfolios';
import { fetchCountriesAction } from '../actions/countries';
import {
  createDigitalPricingListAction,
  createPackageAction,
  deleteDigitalPricingListAction,
  deletePackageAction,
  fetchDigitalPricingListsAction,
  setCurrentDigitalPricingListAction,
  updateDigitalPricingListAction,
  updatePackageAction
} from '../actions/digital_pricing';
import { trackEventAction } from '../actions/analytics';
import { showUpgradeModalSaga } from './auth';
import {
  createMessageAction,
  deleteMessageAction,
  fetchMessagesAction,
  updateMessageAction,
  uploadMessageImageAction
} from '../actions/message';
import {
  createNewPortfolioAction,
  deletePortfolioAction,
  deleteTitleImageAction,
  fetchPortfoliosAction,
  fetchSinglePortfolioAction,
  updatePortfoliosByPathAction,
  uploadPortfolioImageAction
} from '../actions/portfolios';

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
  yield fork(global.getChecksumsSaga);
  yield fork(images.watchRequest);
  yield fork(images.checkAndUploadOfflineImagesQueueSaga);
  yield takeEvery(trackEventAction, analytics.trackEventSaga);
  // Global
  yield takeLatest(showNotifyFail, global.notifyFailSaga);
  yield takeLatest(showNotifySuccess, global.notifySuccessSaga);
  yield debounce(100, fetchCountriesAction, countries.fetchCountriesSaga);
  // Collections
  yield takeLatest(fetchCollection, collections.initialCollectionFetchSaga);
  yield takeLatest(deleteCollectionCustomDomain, collections.deleteCollectionCustomDomainSaga);
  yield takeLatest(
    setEndCustomerEmailSubscription,
    collections.setEndCustomerEmailSubscriptionSaga
  );
  yield takeEvery(updateCollectionByPath, collections.updateCollectionByPathSaga);
  yield takeLatest(updateCollectionDomain, collections.updateCollectionDomainSaga);
  yield takeLatest(updateMainCustomers, collections.updateMainCustomersSaga);
  yield takeLatest(changeCollectionState, collections.changeCollectionStateSaga);
  yield takeLatest(deleteMainCustomer, collections.deleteMainCustomerSaga);
  yield takeLatest(getPin, collections.getPinSaga);
  yield takeLatest(setAppIcon, collections.setAppIconSaga);
  yield takeLatest(searchCollectionsAction, collections.searchCollectionsSaga);
  yield takeLatest(setCollectionHeader, collections.setCollectionHeaderSaga);
  // Galleries
  yield takeLatest(fetchGalleries, galleries.fetchGalleriesSaga);
  yield takeLatest(fetchGallery, galleries.fetchGallerySaga);
  yield takeLatest(createGallery, galleries.createGallerySaga);
  yield takeLatest(deleteGallery, galleries.deleteGallerySaga);
  yield takeLatest(updateGallery, galleries.updateGallerySaga);
  yield takeLatest(changeGalleryOrder, galleries.changeGalleryOrderSaga);
  yield takeLatest(toggleGalleryWatermark, galleries.toggleGalleryWatermarkSaga);
  yield takeLatest(galleryUploadingAction, galleries.galleryUploadingActionSaga);
  yield takeLatest(galleryUploadingClearAction, galleries.galleryUploadingClearActionSaga);
  yield takeLatest(galleriesUploadingClearAction, galleries.galleriesUploadingClearActionSaga);
  yield takeLatest(multipleGalleryUpdate, galleries.multipleGalleryUpdateSaga);
  yield takeLatest(multipleWatermarkGalleryUpdate, galleries.multipleWatermarkGalleryUpdateSaga);
  yield takeLatest(startUploadGalleryVideo, galleries.uploadGalleryVideoSaga);
  yield takeLatest(deleteGalleryVideo, galleries.deleteGalleryVideoSaga);
  // Slideshow
  yield takeLatest(createSlideshow, slideshow.createSlideshowSaga);
  yield takeLatest(fetchSlideshow, slideshow.fetchSlideshowSaga);
  yield takeLatest(updateSlideshow, slideshow.updateSlideshowSaga);
  yield takeLatest(removeSlides, slideshow.removeSlidesSaga);
  yield takeLatest(addAdditionalPhotos, slideshow.addPhotosSaga);
  yield takeLatest(addTitleSlide, slideshow.addTitleSlideSaga);
  yield takeLatest(reorderSlideshow, slideshow.reorderSlidesSaga);
  yield takeLatest(editSlideTitle, slideshow.editTitleSlideSaga);
  yield takeLatest(removeSoundtrack, slideshow.removeSountrackSaga);
  yield takeLatest(undoAction, slideshow.undoSlideshowSaga);
  yield takeLatest(redoAction, slideshow.redoSlideshowSaga);
  yield takeLatest(changeSlideshowParameters, slideshow.changeSlideshowParametersSaga);
  yield takeLatest(reorderTracks, slideshow.reorderSoundtracksSaga);
  yield takeLatest(scheduleRenderAction, slideshow.scheduleRenderSaga);
  yield takeLatest(cancelRenderAction, slideshow.cancelRenderSaga);
  yield takeLatest(getRenderServiceStatusAction, slideshow.getRenderServicStatusSaga);
  yield takeLatest(getRenderStatusAction, slideshow.getRenderStatusSaga);
  yield takeLatest(
    updateUserSlideshowOnboardingAction,
    slideshow.updateUserSlideshowOnboardingSaga
  );
  yield takeLatest(addLogoSlide, slideshow.addLogoSlideSaga);
  yield takeLatest(editLogoSlide, slideshow.editLogoSlideSaga);
  // Music
  yield takeLatest(fetchMusic, music.fetchMusicSaga);
  yield takeLatest(fetchSlideshowMusic, music.fetchSlideshowMusicSaga);
  yield takeLatest(filterMusic, music.filterMusicSaga);
  yield takeLatest(fetchFavoriteMusic, music.fetchFavoriteMusicSaga);
  yield takeLatest(addMusicToFavorites, music.addMusicToFavoritesSaga);
  yield takeLatest(removeFavorites, music.removeFavoriteMusicSaga);
  yield takeLatest(loadMore, music.loadMoreSaga);
  // SlideshowsList
  yield takeEvery(fetchSlideshowsList, slideshowsList.fetchSlideshowsListSaga);
  yield takeLatest(removeSlideshow, slideshowsList.removeSlideshowSaga);
  yield takeLatest(changeSlideshowPublishStatus, slideshowsList.changeSlideshowPublishStatusSaga);
  // Images
  yield takeLatest(fetchImages, images.fetchImagesSaga);
  yield takeLatest(setImageTitle, images.setImageTitleSaga);
  yield takeLatest(setFocalPointAction, images.setFocalPointSaga);
  yield takeLatest(setSmallScreenImageTitle, images.setSmallScreenImageTitleSaga);
  yield takeLatest(deleteImage, images.deleteImageSaga);
  yield takeLatest(deleteImages, images.deleteImagesSaga);
  yield takeLatest(deleteGalleryImages, images.deleteGalleryImagesSaga);
  yield takeLatest(selectImage, images.selectImageSaga);
  yield takeLatest(selectImages, images.selectImagesSaga);
  yield takeLatest(invertSelectedImages, images.invertSelectedImagesSaga);
  yield takeLatest(moveSelectedImagesToGallery, images.moveSelectedImagesToGallerySaga);
  yield takeLatest(actionDropImage, images.actionDropImageSaga);
  yield takeLatest(actionDropMultiImage, images.actionDropMultiImageSaga);
  yield takeLatest(toggleImageWatermark, images.toggleImageWatermarkSaga);
  yield takeEvery(uploadImages, images.uploadImagesSaga);
  yield takeLatest(updateSingleImageData, images.updateSingleImageDataSaga);
  yield takeLatest(selectSelectionImagesByFilter, images.selectSelectionImagesByFilterSaga);
  yield takeLatest(setGifMetaData, images.setGifMetaDataSaga);
  yield takeLatest(deleteHeaderImage, images.deleteHeaderImageSaga);
  // Domains
  yield takeLatest(fetchDomains, domains.fetchDomainsSaga);
  // Templates
  yield takeLatest(fetchTemplates, templates.fetchTemplatesSaga);
  yield takeLatest(fetchTemplatesFonts, templates.fetchTemplatesFontsSaga);
  // Selections
  yield takeLatest(fetchSelections, selection.fetchSelectionsSaga);
  yield takeLatest(selectionCleanUp, selection.selectionCleanUpSaga);
  // Statistics
  yield takeLatest(statisticsInitialFetch, statistics.statisticsInitialFetchSaga);
  yield takeLatest(fetchCollectionStatistics, statistics.fetchCollectionStatisticsSaga);
  yield takeLatest(fetchCollectionStatisticsGraph, statistics.fetchCollectionStatisticsGraphSaga);
  yield takeLatest(
    fetchCollectionStatisticsOverall,
    statistics.fetchCollectionStatisticsOverallSaga
  );
  yield takeLatest(updateGraphFilter, statistics.updateGraphFilterSaga);
  yield takeLatest(refreshActivities, statistics.refreshActivitiesSaga);
  // Subscription
  yield takeLatest(buySubscription, subscription.buySubscriptionSaga);
  yield takeLatest(cancelSubscription, subscription.cancelSubscriptionSaga);
  yield takeLatest(restartSubscription, subscription.restartSubscriptionSaga);
  yield takeLatest(updateBillingAddress, subscription.updateBillingAddressSaga);
  yield takeLatest(fetchPricingAction, paymentInfo.fetchPricing);
  yield takeLatest(calculateDiscountAction, paymentInfo.calculateDiscountSaga);
  yield takeLatest(getProrationsAction, paymentInfo.getProrationsSaga);
  yield takeLeading(getDigistoreBalanceAction, paymentInfo.getDigistoreBalanceSaga);
  yield takeLatest(updateSubscriptionAction, paymentInfo.updateSubscriptionSaga);
  yield takeLatest(updateAddonsAction, paymentInfo.updateAddonsSaga);
  yield takeLatest(setupIntentFailed, subscription.setupIntentFailed);
  yield takeLatest(fetchStripePaymentMethodState, paymentInfo.fetchStripePaymentMethodStateSaga);
  // Log
  yield takeLatest(logUserActivity, global.logUserActivitySaga);
  // Watermarks
  yield takeLatest(startWaterMarkProcessing, watermarks.startWaterMarkProcessingSaga);
  // Presets
  yield takeLatest(createPresetAction, presets.createPresetSaga);
  yield takeLatest(fetchPresetsAction, presets.fetchPresetsSaga);
  yield takeLatest(setPresetAsDefault, presets.setPresetAsDefaultSaga);
  yield takeLatest(deletePreset, presets.deletePresetSaga);
  yield takeLatest(updatePresetAction, presets.updatePresetSaga);
  yield takeLatest(setPresetsHeaderImageSagaAction, presets.setPresetsHeaderImageSaga);
  // Shop
  yield takeLatest(fetchProductPricingListAction, shop.fetchProductPricingListSaga);
  yield takeLatest(createProductPriceListAction, shop.createProductPriceListSaga);
  yield takeLatest(createProductPriceListAction2, shop.createProductPriceListSaga2);
  yield takeLatest(setSelectedPriceListAction, shop.setSelectedPriceListSaga);
  yield takeLatest(renamePricingListAction, shop.renameProductPricingListSaga);
  yield takeLatest(deleteProductPriceListAction, shop.deleteProductPriceListSaga);
  yield takeLatest(fetchProductUserPricingGroupsListAction, shop.fetchPricingGroupSaga);
  yield takeEvery(updatePricingGroupAction, shop.updatePricinGroupSaga);
  yield takeLatest(createDigitalProductAction, shop.createDigitalProductSaga);
  yield takeLatest(updateProductUserPricingsAction, shop.updateProductUserPricingsSaga);
  yield takeLatest(fetchUserPricingAction, shop.fetchUserPricingSaga);
  yield takeEvery(updatePriceValueAction, shop.updatePriceValueSaga);
  yield takeLatest(fetchUserAccountBalanceAction, shop.fetchUserAccountBalanceSaga);
  yield takeLatest(fetchDigitalPricingListsAction, digitalPricing.fetchDigitalPricingListsSaga);
  yield takeLatest(createDigitalPricingListAction, digitalPricing.createDigitalPricingListSaga);
  yield takeLatest(deleteDigitalPricingListAction, digitalPricing.deleteDigitalPricingListSaga);
  yield takeLatest(
    setCurrentDigitalPricingListAction,
    digitalPricing.setCurrentDigitalPricingListSaga
  );
  yield takeLatest(updateDigitalPricingListAction, digitalPricing.updateDigitalPricingListSaga);
  yield takeLatest(createPackageAction, digitalPricing.createPackageSaga);
  yield takeLatest(deletePackageAction, digitalPricing.deletePackageSaga);
  yield takeLatest(updatePackageAction, digitalPricing.updatePackageSaga);
  yield debounce(500, fetchCouponsAction, shop.fetchCouponsListSaga);
  yield takeLatest(createCoupon, shop.createCouponSaga);
  yield takeLatest(deleteCoupon, shop.deleteCouponSaga);
  yield takeLatest(updateCoupon, shop.updateCouponSaga);
  yield takeLatest(fetchProductGroupsAction, shop.fetchProductGroupsSaga);
  yield takeLatest(fetchProductsAction, shop.fetchProductsSaga);
  yield takeLatest(getGlobalShopSettingsForCurrentUser, shop.fetchGlobalShopSettingsSaga);
  yield takeLatest(createGlobalShopSettings, shop.createGlobalShopSettingsSaga);
  yield takeLatest(updateGlobalShopSettings, shop.updateGlobalShopSettingsSaga);
  yield takeLatest(fetchOrderListForUserAction, shop.fetchOrderListForUserSaga);
  yield takeLatest(cancelOrderAction, shop.cancelOrderSaga);
  yield takeLatest(recordPaymentAction, shop.recordPaymentSaga);
  yield takeLatest(fetchOrderImagesAction, shop.fetchOrderImagesActionSaga);
  yield takeLatest(fetchLaboratoriesAction, shop.fetchLaboratoriesSaga);
  yield takeLatest(salesAutomationBulkUpdateAction, shop.salesAutomationBulkUpdateSaga);
  yield takeLatest(fetchPayDocumentsAction, shop.fetchPayDocuments);
  yield takeLatest(userHasEnoughInvoicingDataAction, shop.userHasEnoughInvoicingData);
  // User
  yield takeLatest(updateUserTaxAction, user.updateUserTaxSaga);
  yield takeLatest(fetchPayoutAccountAction, user.fetchPayoutAccountSaga);
  yield takeLatest(getAvailableCurrenciesAction, user.getAvailableCurrenciesSaga);
  yield takeLatest(createPayoutAccountAction, user.createPayoutAccountSaga);
  yield takeLatest(updatePayoutAccountAction, user.updatePayoutAccountSaga);
  yield takeLatest('SET_MODAL_STATE', showUpgradeModalSaga);
  // Email templates
  yield takeLatest(createEmailTemplateAction, emailTemplates.createEmailTemplateSaga);
  yield takeLatest(deleteEmailTemplateAction, emailTemplates.deleteEmailTemplateSaga);
  yield debounce(500, fetchRecipientsAction, emailTemplates.fetchRecipientsSaga);
  yield takeLatest(getEmailTemplatePreviewAction, emailTemplates.getEmailTemplatePreviewActionSaga);
  yield debounce(500, fetchEmailTemplatesAction, emailTemplates.fetchEmailTemplatesSaga);
  yield takeLatest(updateEmailTemplateAction, emailTemplates.updateEmailTemplateSaga);
  yield takeLatest(sendEmailAction, emailTemplates.sendEmailSaga);
  yield takeLatest(sendLoginLinkEmailAction, emailTemplates.sendLoginLinkEmail);
  // Access Rules
  yield takeLatest(accessRulesAction, accessRules.accessRulesSaga);
  yield takeLatest(startTrialAction, trial.startTrialSaga);
  yield takeLatest(fetchPaymentInfoAction, paymentInfo.paymentInfoSaga);
  // Galleries Tags
  yield takeLatest(fetchGalleriesTags, galleriesTags.fetchGalleriesTagsSaga);
  yield takeLatest(addGalleryTagCollectionAction, galleriesTags.addGalleryTagCollectionSaga);
  yield takeLatest(removeGalleryTagCollectionAction, galleriesTags.removeGalleryTagCollectionSaga);
  // Email settings
  yield takeLatest(fetchEmailPackages, emailSettings.fetchEmailPackageSaga);
  yield takeLatest(createEmailPackage, emailSettings.createEmailPackageSaga);
  yield takeLatest(deleteEmailPackage, emailSettings.deleteEmailPackageSaga);
  // Messages
  yield takeLatest(fetchMessagesAction, message.fetchMessagesSaga);
  yield takeLatest(createMessageAction, message.createMessageSaga);
  yield takeLatest(deleteMessageAction, message.deleteMessageSaga);
  yield takeLatest(updateMessageAction, message.updateMessageSaga);
  yield takeLatest(uploadMessageImageAction, message.uploadMessageImageSaga);
  // Portfolios
  yield takeLatest(fetchPortfoliosAction, portfolios.fetchPortfoliosSaga);
  yield takeLatest(fetchSinglePortfolioAction, portfolios.fetchSinglePortfolioSaga);
  yield takeLatest(createNewPortfolioAction, portfolios.createNewPortfolio);
  yield takeLatest(updatePortfoliosByPathAction, portfolios.updatePortfolioByPathSaga);
  yield takeLatest(deletePortfolioAction, portfolios.deletePortfolioSaga);
  yield takeLatest(uploadPortfolioImageAction, portfolios.uploadPortfolioImageSaga);
  yield takeLatest(deleteTitleImageAction, portfolios.deleteTitleImageSaga);
}
