import { put, call, delay, select } from 'redux-saga/effects';
import { changeModalState, errorsGlobalError, notify, setAppVersion } from '../../actions';
/* tslint:disable */
import Texts from '../../../json/texts.json';
import { IDictionary, ISagaAction } from '../../types';
import { SagaIterator } from 'redux-saga';
import ApiErrors from '../../utils/API/APIErrors';
import Api from '../../utils/API';
import axios from '../../../helpers/axios';
import { getAppVersion, getAppVersionDelay } from '../../selectors';

export const notifySuccessSaga = function* ({ payload }: ISagaAction<{ message?: string }>) {
  yield put(
    notify({
      // @ts-ignore
      title: Texts[window.LANGUAGE_SW].success,
      // @ts-ignore
      message: payload?.message || Texts[window.LANGUAGE_SW].saved,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 1500
    })
  );
};

export const notifyFailSaga = function* ({ payload }: ISagaAction<{ message?: string } | void>) {
  yield put(
    notify({
      // @ts-ignore
      title: Texts[window.LANGUAGE_SW].error,
      // @ts-ignore
      message: payload?.message || Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 5000
    })
  );
};

export const showNotifyMessageSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  yield put(
    notify({
      message: payload,
      status: 'warning',
      position: 'tc',
      dismissible: true,
      dismissAfter: 10000
    })
  );
};

const maxTries = 5;
// const log = ( e: any ) => {
// 	window.logToServer( { message: e.message } );
// }
export const multipleAttempts = (generator: any) =>
  function* multipleAttempts(...args: any) {
    let n = 0;
    let delayTime = 5;
    let response = {};
    while (n < maxTries) {
      try {
        response = yield call(generator, ...args);
        ApiErrors.checkOnApiError(response);

        return response;
      } catch (e) {
        response = {
          error: e
        };
        console.error(e);
        // yield log( e );
      }
      if (n < maxTries - 1) {
        yield delay(delayTime * 1000);
        delayTime += 10;
      }

      n++;
    }

    return response;
  };

export const logUserActivitySaga = function* ({
  payload
}: ISagaAction<IDictionary<any>>): SagaIterator {
  try {
    const response = yield call(Api.Log.useractivitylog, payload);
    ApiErrors.checkOnApiError(response);
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const getChecksumsSaga = function* (): SagaIterator {
  while (true) {
    const checkSumDelay = yield select(getAppVersionDelay);
    try {
      const appVersion = yield select(getAppVersion);
      const { data } = yield call(axios.get, `${window.location.origin}/checksums.json`);

      if (appVersion && appVersion !== data.app_version) {
        yield put(setAppVersion(data.app_version));
        yield put(
          changeModalState({
            key: 'appVersionModal',
            state: true
          })
        );
      } else {
        yield put(setAppVersion(data.app_version));
      }
    } catch (e) {
      console.log(e);
    }
    yield delay(checkSumDelay);
  }
};
