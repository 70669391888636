import { createReducer } from 'redux-act';
import { putPaymentInfoAction } from '../../actions';
import { IPaymentInfo } from '../../types/paymentInfo';

const initialState: IPaymentInfo = {
  address: {
    city: '',
    company: '',
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    street: '',
    zipcode: ''
  },
  invoices: [{ date: '', pdfUrl: '', productName: '', value: '' }],
  oneTimePurchases: [],
  paymentProvider: '',
  subscriptions: [
    {
      lastInvoiceId: null,
      stripeSubscriptionId: null,
      amountCents: null,
      amountCentsGross: null,
      nextPaymentAt: null,
      paymentMethod: null,
      renewUrl: null,
      receiptUrl: null,
      subscriptionType: '',
      productName: '',
      isPaymentProblem: false
    }
  ],
  userId: '',
  pricing: {
    addonPricing: {},
    corePricing: {},
    templates: []
  },
  discount: {
    discountTotal: 0,
    firstCorePaymentDiscount: 0,
    templateDiscount: 0,
    templateId: null,
    freeSlideshow: false
  },
  prorations: {
    loading: false,
    remainingBalanceCents: 0,
    prorationCents: 0,
    totalDiscountCents: 0,
    balanceCents: 0,
    upcommingInvoiceAmountCents: 0,
    prorationDate: null
  },
  updatingAddress: false,
  showStripeMigrationNote: false,
  digistoreBalance: 0,
  userHasPaymentMethod: true
};

export const paymentInfo = createReducer<IPaymentInfo>({}, initialState);

paymentInfo.on(putPaymentInfoAction, (state: IPaymentInfo, payload: any) => ({
  ...state,
  ...payload
}));
