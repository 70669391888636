/* eslint-disable */
import React, {useCallback, useEffect, useState} from 'react';
import {InputItem} from '../Forms/InputItem';
import {SwitchItem} from '../Forms/SwitchItem';
import {debounce, partial, set} from 'lodash';
import {getTranslationKey} from '../../modules/utils';
import {SalesCouponSelection} from './SalesCouponSelection';
import {COLLECTION_SHOP_AUTOMATION_DEFAULTS} from '../../modules/constants';
import {IShopSalesAutomation, IStoreState} from '../../modules/types';
import {FormTooltip} from '../Forms/InputTooltip';
import styled from 'styled-components';
import {Alert} from 'reactstrap';
import {useSelector} from 'react-redux';
import {getProductUserPricing, getUser} from '../../modules/selectors';
import {SelectItem} from '../Forms/SelectItem';
import DatePicker from '../DatePicker';
import moment from 'moment';

const SelectWrapper = styled.div`
  max-width: 360px;
`;

const InlineForm = styled.div<{ offsetLeft?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.offsetLeft && 'margin-left: 38px;'}
`;
const SmallInputWrapper = styled.div`
  &:not(:first-child) {
    margin-left: 20px;
  }

  max-width: 140px;
  display: flex;
  align-items: center;

  .form-tooltip {
    margin-left: 5px;
  }
`;

const switchDirection = 'row-reverse';
const formMargin = '15px 0 20px 0';
const codeAdvLink = 'https://support.scrappbook.de/de/articles/61943';
const abandonedCartLink = 'https://support.scrappbook.de/de/articles/61945';
const emptyCartSuggestionLink = 'https://support.scrappbook.de/de/articles/61946';
const freeShippingLink = 'https://support.scrappbook.de/de/articles/61947';
const afterPurchaseLink =
  'https://support.scrappbook.de/de/articles/61948-automatischer-gutscheincode-nach-dem-kauf';

interface IProps {
  isShopActive: boolean;
  updateCallback?: (data: IShopSalesAutomation) => void;
  values: IShopSalesAutomation;
  debounceUpdates?: boolean;
  updateWithInvalidValues?: boolean;
  scheduleType?: 'datepicker' | 'input';
}

// tslint:disable-next-line:max-func-body-length
export const SalesAutomationForm: React.FC<IProps> = ({
  isShopActive,
  updateCallback,
  values,
  debounceUpdates = true,
  updateWithInvalidValues = false,
  scheduleType = 'input'
}) => {
  const { selectionOptions } = useSelector((state: IStoreState) => state.emailSettings);
  const productUserPriceList = useSelector(getProductUserPricing);
  const user = useSelector(getUser);

  const [automationState, setAutomationState] = useState(COLLECTION_SHOP_AUTOMATION_DEFAULTS);

  const { couponCodeAdvertising, marketingPhotobooks } = automationState;
  const isFormDisabled = !isShopActive;
  const filteredPriceList = productUserPriceList.filter(
    (item) => item.active && item._product._productGroup.slug === 'photobook'
  );
  const hasPhotobooks =
    productUserPriceList && productUserPriceList.length > 0 && filteredPriceList.length > 0;

  const callUpdateCallback = (value: any) => {
    if (updateCallback) {
      updateCallback(value);
    }
  };

  let debouncedCallback;

  debouncedCallback = useCallback(debounce(callUpdateCallback, 1000), [values]);
  if (!debounceUpdates) {
    debouncedCallback = callUpdateCallback;
  }

  const saveValues = debouncedCallback;

  useEffect(() => {
    if (values && values !== automationState) {
      setAutomationState(values);
    }
  }, [values]); // eslint-disable-line

  const validateData = (data: IShopSalesAutomation) => {
    const { endsAfterDays: couponEndsAfterDays, startInDays: couponStartInDays } =
      data.couponCodeAdvertising;
    const isCouponEndsAfterDaysValidated = couponEndsAfterDays < 1;
    const isCouponStartInDaysValidated = couponStartInDays < 0;

    const { endsAfterDays: marketingEndsAfterDays, startInDays: marketingStartInDays } =
      data.marketingPhotobooks;
    const isMarketingEndsAfterDaysValidated = marketingEndsAfterDays < marketingStartInDays;
    const isMarketingStartInDaysValidated = marketingPhotobooks.startInDays < 0;

    if (!isCouponEndsAfterDaysValidated) {
      return;
    }

    if (!isMarketingEndsAfterDaysValidated) {
      return;
    }

    if (!isCouponStartInDaysValidated) {
      return;
    }

    return {
      ...automationState,

      couponCodeAdvertising: {
        ...couponCodeAdvertising,
        endsAfterDays: isCouponEndsAfterDaysValidated ? 1 : Number(couponEndsAfterDays),
        startInDays: isCouponStartInDaysValidated ? 0 : Number(couponStartInDays)
      },

      marketingPhotobooks: {
        ...marketingPhotobooks,
        endsAfterDays: isMarketingEndsAfterDaysValidated
          ? Number(marketingStartInDays) + 1
          : Number(marketingEndsAfterDays),
        startInDays: isMarketingStartInDaysValidated ? 0 : Number(marketingStartInDays)
      }
    };
  };

  const isOneCouponUsedTwice =
    automationState.couponCodeAdvertising.couponId &&
    automationState.couponCodeAfterPurchase.couponId &&
    automationState.couponCodeAfterPurchase.couponId ===
      automationState.couponCodeAdvertising.couponId;

  const updateFormValue = (fieldPath: string, value: unknown) => {
    const isNumValue = [
      'couponCodeAdvertising.startInDays',
      'couponCodeAdvertising.endsAfterDays',
      'freeShipping.startsFrom',
      'couponCodeAdvertising.amountOfImagesBetweenBanners',
      'galleryExpires.expiresInDays',
      'marketingPhotobooks.startInDays',
      'marketingPhotobooks.endsAfterDays'
    ].includes(fieldPath);

    const fieldValue = isNumValue && value !== '' ? Number(value) : value;
    const data = set({ ...automationState }, fieldPath, fieldValue);

    const isEndDaySmallerThanStartDay =
      data.couponCodeAdvertising.endsAfterDays <= data.couponCodeAdvertising.startInDays;

    if (isEndDaySmallerThanStartDay && scheduleType === 'datepicker') {
      data.couponCodeAdvertising.endsAfterDays = data.couponCodeAdvertising.startInDays + 1;
    }

    setAutomationState(data);

    const isOneCouponUsedTwice =
      data.couponCodeAdvertising.couponId &&
      data.couponCodeAfterPurchase.couponId &&
      data.couponCodeAfterPurchase.couponId === data.couponCodeAdvertising.couponId;

    const isInvalid = isOneCouponUsedTwice || (isNumValue && value === '');
    if (!updateWithInvalidValues && isInvalid) return;

    const validated = validateData(data);
    saveValues(validated || data);
  };

  const photobookMarketingDisabled = productUserPriceList.length === 0 || !hasPhotobooks;

  const daysFromToday = (dateStr: string): number => {
    const date = moment(new Date(dateStr)).startOf('day');
    const today = moment().startOf('day');
    return date.diff(today, 'days');
  };

  const dateFromDays = (days: number): string => {
    return moment().startOf('day').add(days, 'days').toDate().toString();
  };

  const updateStartDate = (dateStr: string) => {
    if (!dateStr) {
      setAutomationState((prev) => {
        const updated = {
          ...prev,
          couponCodeAdvertising: {
            ...prev.couponCodeAdvertising,
            startInDays: 0,
            endsAfterDays: 1
          }
        };
        saveValues(updated);
        return updated;
      });
      return;
    }

    const startInDays = daysFromToday(dateStr);

    setAutomationState((prev) => {
      const currentEndDateAbsolute =
        prev.couponCodeAdvertising.startInDays + prev.couponCodeAdvertising.endsAfterDays;

      let newEndsAfterDays = currentEndDateAbsolute - startInDays;
      if (newEndsAfterDays < 1) {
        newEndsAfterDays = 1;
      }

      const updated = {
        ...prev,
        couponCodeAdvertising: {
          ...prev.couponCodeAdvertising,
          startInDays,
          endsAfterDays: newEndsAfterDays
        }
      };

      saveValues(updated);
      return updated;
    });
  };

  const updateEndDate = (dateStr: string) => {
    if (!dateStr) {
      setAutomationState((prev) => {
        const updated = {
          ...prev,
          couponCodeAdvertising: {
            ...prev.couponCodeAdvertising,
            endsAfterDays: 1
          }
        };
        saveValues(updated);
        return updated;
      });
      return;
    }

    let endDateDaysFromToday = daysFromToday(dateStr);

    setAutomationState((prev) => {
      let endsAfterDays = endDateDaysFromToday - prev.couponCodeAdvertising.startInDays;
      if (endsAfterDays < 1) {
        endsAfterDays = 1;
      }

      const updated = {
        ...prev,
        couponCodeAdvertising: {
          ...prev.couponCodeAdvertising,
          endsAfterDays
        }
      };

      saveValues(updated);
      return updated;
    });
  };

  return (
    <>
      <SelectWrapper>
        <SelectItem
          label={getTranslationKey('shop.email-settings.package-title')}
          options={selectionOptions}
          value={automationState?.emailPackage}
          changeCallback={partial(updateFormValue, 'emailPackage')}
        />
      </SelectWrapper>

      <SwitchItem
        value={automationState.couponCodeAdvertising?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'couponCodeAdvertising.enabled')}
        label={getTranslationKey('collectionShop.couponToggleLabel')}
        direction={switchDirection}
        margin="15px 0 5px 0"
        id="couponCodeAdvertising-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: codeAdvLink,
          text_link: codeAdvLink
        })}
        isIconTooltip
      />

      <InlineForm offsetLeft>
        <SalesCouponSelection
          isDisabled={isFormDisabled}
          value={String(automationState.couponCodeAdvertising?.couponId)}
          changeCallback={partial(updateFormValue, 'couponCodeAdvertising.couponId')}
        />
        <SmallInputWrapper>
          <InputItem
            disabled={isFormDisabled}
            label={getTranslationKey('collectionShop.frequency')}
            type="number"
            min={0}
            max={1000}
            value={automationState.couponCodeAdvertising?.amountOfImagesBetweenBanners}
            changeCallback={partial(
              updateFormValue,
              'couponCodeAdvertising.amountOfImagesBetweenBanners'
            )}
            id="couponCodeAdvertising-amountOfImagesBetweenBanners"
            withTooltip
            tooltipText={getTranslationKey('collectionShop.frequencyTooltip')}
            tooltipIconPlacement="label"
          />
        </SmallInputWrapper>
      </InlineForm>
      {scheduleType === 'datepicker' && (
        <InlineForm offsetLeft>
          <SmallInputWrapper>
            <DatePicker
              date={dateFromDays(automationState.couponCodeAdvertising?.startInDays || 0)}
              callback={updateStartDate}
              placeholder={getTranslationKey('collectionShop.startOnLabel')}
              label={getTranslationKey('collectionShop.startOnLabel')}
              minDate={new Date()}
              defaultDate={new Date().toString()}
            />
          </SmallInputWrapper>
          <SmallInputWrapper>
            <DatePicker
              date={dateFromDays(
                automationState.couponCodeAdvertising?.startInDays +
                  automationState.couponCodeAdvertising?.endsAfterDays || 1
              )}
              callback={updateEndDate}
              placeholder={getTranslationKey('collectionShop.endInLabel')}
              label={getTranslationKey('collectionShop.endInLabel')}
              minDate={
                new Date(dateFromDays(automationState.couponCodeAdvertising?.startInDays + 1 || 1))
              }
              defaultDate={
                new Date(dateFromDays(automationState.couponCodeAdvertising?.startInDays + 1))
              }
            />
          </SmallInputWrapper>
        </InlineForm>
      )}
      {scheduleType === 'input' && (
        <InlineForm offsetLeft>
          <SmallInputWrapper>
            <InputItem
              disabled={isFormDisabled}
              label={getTranslationKey('collectionShop.startInLabel')}
              pattern={/^[0-9]+$/i}
              min={0}
              value={automationState.couponCodeAdvertising?.startInDays}
              changeCallback={partial(updateFormValue, 'couponCodeAdvertising.startInDays')}
              rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
              withTooltip={false}
              id={`collectionShop-startInDays-daysPostfix`}
            />
          </SmallInputWrapper>

          <SmallInputWrapper>
            <InputItem
              disabled={isFormDisabled}
              label={getTranslationKey('collectionShop.endInLabel')}
              pattern={/^[0-9]+$/i}
              min={1}
              value={automationState.couponCodeAdvertising?.endsAfterDays}
              changeCallback={partial(updateFormValue, 'couponCodeAdvertising.endsAfterDays')}
              rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
              withTooltip={false}
              id={`collectionShop-endsAfterDays-daysPostfix`}
            />
          </SmallInputWrapper>
        </InlineForm>
      )}

      <SwitchItem
        value={automationState.abandonedShoppingCartReminder?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'abandonedShoppingCartReminder.enabled')}
        label={getTranslationKey('collectionShop.abandonedShoppingLabel')}
        direction={switchDirection}
        margin={formMargin}
        id="abandonedShoppingCartReminder-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: abandonedCartLink,
          text_link: abandonedCartLink
        })}
        isIconTooltip
      />

      <SwitchItem
        value={automationState.emptyCartProductSuggestion?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'emptyCartProductSuggestion.enabled')}
        label={getTranslationKey('collectionShop.emptyCartLProductLabel')}
        direction="row-reverse"
        margin={formMargin}
        id="emptyCartProductSuggestion-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: emptyCartSuggestionLink,
          text_link: emptyCartSuggestionLink
        })}
        isIconTooltip
      />

      <InlineForm>
        <SwitchItem
          value={automationState.freeShipping?.enabled}
          isDisabled={isFormDisabled}
          changeCallback={partial(updateFormValue, 'freeShipping.enabled')}
          label={getTranslationKey('collectionShop.freeShippingLabel')}
          direction={switchDirection}
          margin={formMargin}
          id="freeShipping-switch"
        />

        <SmallInputWrapper>
          <InputItem
            pattern={/^[0-9]+$/i}
            disabled={isFormDisabled}
            changeCallback={partial(updateFormValue, 'freeShipping.startsFrom')}
            value={automationState.freeShipping?.startsFrom}
            placeholder="-"
            min={0}
            step="0.1"
            margin="0"
            rightAddonElement={user.currency}
            id={`freeShipping-switch-input`}
          />

          <FormTooltip
            isShow
            formTheme="main"
            tooltipText={getTranslationKey('learnMoreLink', {
              link: freeShippingLink,
              text_link: freeShippingLink
            })}
            tooltipID="freeShipping-switch"
          />
        </SmallInputWrapper>
      </InlineForm>

      <SwitchItem
        value={automationState.couponCodeAfterPurchase?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'couponCodeAfterPurchase.enabled')}
        label={getTranslationKey('collectionShop.couponCodeAfterLabel')}
        direction={switchDirection}
        margin="15px 0 5px 0"
        id="couponCodeAfterPurchase-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: afterPurchaseLink,
          text_link: afterPurchaseLink
        })}
        isIconTooltip
      />

      <SalesCouponSelection
        isDisabled={isFormDisabled}
        value={String(automationState.couponCodeAfterPurchase?.couponId)}
        changeCallback={partial(updateFormValue, 'couponCodeAfterPurchase.couponId')}
        styles={{
          marginLeft: '38px'
        }}
      />

      <SwitchItem
        value={automationState.marketingPhotobooks?.enabled}
        isDisabled={isFormDisabled || !hasPhotobooks}
        changeCallback={partial(updateFormValue, 'marketingPhotobooks.enabled')}
        label={getTranslationKey('collectionShop.marketingPhotobook')}
        direction={switchDirection}
        margin="15px 0 5px 0"
        id="marketingPhotobooks-switch"
        withTooltip
        tooltipText={getTranslationKey('collectionShop.findOutMore')}
        isIconTooltip
      />

      <InlineForm offsetLeft>
        <SmallInputWrapper>
          <InputItem
            disabled={isFormDisabled}
            label={getTranslationKey('collectionShop.startInLabel')}
            pattern={/^[0-9]+$/i}
            min={0}
            value={automationState.marketingPhotobooks?.startInDays}
            changeCallback={partial(updateFormValue, 'marketingPhotobooks.startInDays')}
            rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
          />
        </SmallInputWrapper>

        <SmallInputWrapper>
          <InputItem
            disabled={isFormDisabled}
            label={getTranslationKey('collectionShop.endInLabel')}
            pattern={/^[0-9]+$/i}
            min={1}
            value={automationState.marketingPhotobooks?.endsAfterDays}
            changeCallback={partial(updateFormValue, 'marketingPhotobooks.endsAfterDays')}
            rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
          />
        </SmallInputWrapper>
      </InlineForm>

      {!hasPhotobooks && (
        <Alert color="danger" style={{ marginLeft: '38px' }}>
          {getTranslationKey('collectionShop.marketingPhotobookDisabled')}
        </Alert>
      )}

      {isOneCouponUsedTwice && (
        <Alert color="danger" style={{ marginLeft: '38px' }}>
          {getTranslationKey('collectionShop.couponCannotBeUsedTwice')}
        </Alert>
      )}
    </>
  );
};
