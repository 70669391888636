import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import { LabelItem } from '../Forms/LabelItem';
import { InputTheme } from '../Forms/InputStyled';
import theme from '../../assets/css/theme';
import { UncontrolledTooltip } from 'reactstrap';

const LabelItemStyled = styled(LabelItem)`
  width: fit-content;
`;

const Wrapper = styled.div`
  margin-bottom: 10px;

  .rw-widget-picker {
    border-radius: 20px;
    border: 1px solid transparent;

    &:not(:last-child) {
      border-right: none;
    }

    &::placeholder {
      font-weight: normal;
      color: ${theme.commonColors.main};
      opacity: 1;
    }

    &:focus {
      padding-left: 10px;
    }

    ${InputTheme.main};
  }

  .rw-select {
    border-left: none;
    padding-right: 4px;

    &:hover {
      background: #ffffff;
    }

    .rw-btn {
      color: ${theme.commonColors.second};
    }
  }

  .tooltip-icon {
    color: ${theme.commonColors.second};
    margin-left: 5px;
  }
`;

interface IProps {
  readonly date: string | null;
  readonly placeholder?: string;
  readonly className?: string;
  readonly callback: (value: string) => void;
  readonly label?: string;
  readonly defaultDate?: string | Date | null;
  readonly withTime?: boolean;
  readonly withTooltip?: boolean;
  readonly id?: string;
  readonly tooltipPlacement?: string;
  readonly tooltipText?: string;
  readonly wrapperClassName?: string;
  readonly minDate?: Date;
  readonly dropUp?: boolean;
}

export const DatePicker = ({
  date,
  placeholder,
  className,
  callback,
  label,
  defaultDate = null,
  withTime = false,
  withTooltip,
  id,
  tooltipPlacement,
  wrapperClassName,
  tooltipText,
  minDate,
  dropUp = false
}: IProps) => {
  const [internalDate, setInternalDate] = useState<Date | null>(() => {
    if (!defaultDate) return null;

    const initialDate =
      defaultDate instanceof Date
        ? defaultDate
        : typeof defaultDate === 'string'
        ? moment(defaultDate).toDate()
        : null;

    return initialDate || null;
  });

  const pickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!defaultDate) return; // use legacy mode: controlled via props only

    const parsedDate = date ? moment(date).toDate() : null;
    if (!parsedDate) {
      setInternalDate(null);
      return;
    }

    if (!moment(parsedDate).isSame(internalDate, withTime ? 'minute' : 'day')) {
      setInternalDate(parsedDate);
    }
  }, [date, defaultDate, withTime]);

  const handleChange = (value: Date | null) => {
    if (!value) {
      setInternalDate(null);
      callback('');
      return;
    }

    if (defaultDate) {
      setInternalDate(value);
    }

    callback(value.toISOString());
  };

  const handleBlur = () => {
    if (pickerRef.current) {
      const input = pickerRef.current.querySelector('input');
      if (input) {
        const parsedDate = moment(
          input.value,
          ['DD.MM.YYYY', 'MM/DD/YYYY', moment.ISO_8601],
          true
        ).toDate();
        if (!parsedDate || isNaN(parsedDate.getTime())) {
          input.value = '';
          setInternalDate(null);
          callback('');
        }
      }
    }
  };

  const tooltipID = `${id}_tooltip`;
  const tooltipIcon =
    withTooltip && id ? <i className="fa fa-icon fa-question-circle tooltip-icon" /> : null;
  const tooltip =
    withTooltip && id ? (
      <UncontrolledTooltip
        autohide={false}
        placement={tooltipPlacement as any}
        target={tooltipID}
        dangerouslySetInnerHTML={{ __html: tooltipText || '' }}
      />
    ) : null;

  const useNewMode = !!defaultDate;
  const pickerDate = useNewMode ? internalDate : date ? moment(date).toDate() : null;

  return (
    <Wrapper className={wrapperClassName}>
      {label ? (
        <LabelItemStyled isSmall isBold id={tooltipID}>
          {label}
          {tooltipIcon}
        </LabelItemStyled>
      ) : null}
      {tooltip}
      <div ref={pickerRef}>
        <DateTimePicker
          time={withTime}
          value={pickerDate}
          placeholder={placeholder}
          className={className}
          onChange={handleChange}
          onBlur={handleBlur}
          min={minDate}
          dropUp={dropUp}
        />
      </div>
    </Wrapper>
  );
};

export default DatePicker;
